/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(18, 18, 18, 0.1);
  width: 8px
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(18, 18, 18, 0.1);
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: rgba(18, 18, 18, 0.1);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(160, 160, 165, 0.2);
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(160, 160, 165, 0.5);
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none
}

.scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}