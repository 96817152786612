$main-font: "Metal Mania";
$skia-font: "Skia";
$lands-font: "Oldenburh";
$initial-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$red-color: #F00C0C;
$grey-color: rgb(212,212,212);
$dark-grey: rgb(183,183,183);
$secondary-bg: rgb(18, 18, 18);

@font-face {
  font-family: $main-font;
  src: url("../fonts/MetalMania-Regular.woff") format("woff"),
  url("../fonts/MetalMania-Regular.ttf") format("ttf");
  font-display: fallback;
}

@font-face {
  font-family: $skia-font;
  font-style: normal;
  font-weight: normal;
  src: local("Skia"),
  url("../fonts/ufonts.com_skia.woff") format("woff"),
  url("../fonts/SkiaRegular.ttf") format("ttf");
}

@font-face {
  font-family: $lands-font;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MetalMania-Regular.ttf") format("ttf");
  font-display: fallback;
}
