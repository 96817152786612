@import "./variables/variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

a {
  text-decoration: none!important;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

h1 {
  color: #cfcbcb;
  text-align: center;
}

.btn--spec-filled span {
  font-family: "Metal Mania", serif!important;
  font-weight: normal!important;
}

.btn--spec-filled:hover::after {
  background: none;
}

.btn--spec-filled:hover {
  text-decoration: none !important;
}

.btn--spec-filled {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  min-width: 18rem!important;
  min-height: 4rem!important;

  background: url(../resources/images/button/btn-decor.png) center center no-repeat transparent;

  background-size: 100%;
}

.btn--spec-filled-active,
.btn--spec-filled:hover {
  background-image: url(../resources/images/button/btn.png);
}

.btn--spec-filled-active-green {
  background-image: url(../resources/images/button/button_green_bg.png) !important;
  min-height: 2rem !important;
}

.btn--spec-filled-disabled {
  background-image: url(../resources/images/button/button-disabled.png) !important;
  min-height: 2rem !important;
}

.btn--spec-filled span {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 16px;
  z-index: 1;
}

.not-active-button {
  background: none!important;
}

.less {
  min-width: 10rem!important;
}

.processing {
  font-size: 20px;
}

.tooltip-text {
  font-size: 18px;
  font-family: "Metal Mania", serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.common { color: lightgray!important; }

.rare { color: #8cb630!important; }

.epic { color: #a66cc5!important; }

.legendary { color: #8cc6fc!important; }

.mythical { color: #e84d19!important; }

.dc { color: #edbf42!important; }

.wax-color {color: #fac420; }

.flow-color {color: #00f08c; }

.aptos-color {color: #00b4f0; }

.align-center {
  align-items: center!important;
}

.margin-center {
  margin: 0 auto;
}

.text-align-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.space-between-row-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.opacity-visible {
  opacity: 1;
}

.opacity-hidden {
  opacity: 0;
}

.z-index-10 {
  z-index: 10;
}

.z-index-12 {
  z-index: 12;
}

.height-initial {
  height: initial;
}

.height-100vh {
  height: 100vh;
}

.display-none {
  display: none !important;
}

.cursor-initial {
  cursor: initial;
}

.cursor-pointer {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.maintenance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    font-size: 60px;
    font-family: $main-font;
    color: red;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 50%);
  }
}
